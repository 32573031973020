import React, { useEffect, useState } from "react";

import { WalletProviderId } from "@keyfi/keyfi-common";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as Verified } from "../../assets/email/email-verified.svg";
import MetaMask from "../../assets/wallets/metaMask.svg";
import SelfKey from "../../assets/wallets/selfkey.svg";
import walletConnect from "../../assets/wallets/walletConnect.svg";
import { ReactComponent as Info } from "../../assets/warning.svg";
import { beautifyTokensAmount, tokensLogos, walletsLogos } from "../../helpers";
import { appActions } from "../../redux/appSlice";
import { userDataOperations } from "../../redux/userDataSlice";
import { walletOperations } from "../../redux/walletSlice";
import { ErrorMessage, SpinningLoader } from "../../Shared";
import Button from "../Button";
import Modal from "../Modal";

const ConnectWalletModal = styled.div`
  color: ${(props) => props.theme.default_color};
  h2 {
    margin: 1.5rem 0 1rem;
    font-size: 20px;
  }
  .connect_modal_list {
    margin-bottom: 2rem;
    .connect_modal_item {
      display: flex;
      align-items: center;
      font-size: 20px;
      padding: 1rem;
      cursor: pointer;
      font-weight: 500;
      border-radius: 6px;
      margin-bottom: 1rem;
      img {
        height: 40px;
        width: 40px;
        margin-right: 10px;
      }
      :hover {
        background: ${(props) => props.theme.bg};
      }
    }
  }
`;

const LoadingModalContainer = styled.div`
  color: ${(props) => props.theme.default_color};
  > div {
    margin-top: 1.5rem;
    font-weight: 500;
    font-size: 16px;
  }
  .loading_modal_selected {
    display: flex;
    align-items: center;
    background: ${(props) => props.theme.bg};
    padding: 1rem;
    border-radius: 6px;
    img {
      height: 40px;
      width: 40px;
      margin-right: 10px;
    }
    h3 {
      flex: 1;
    }
    svg {
      height: 32px;
      width: 32px;
    }
  }
`;

const CheckingWrapper = styled.div`
  * {
    color: ${(props) => props.theme.default_color};
  }
  p {
    font-size: 16px;
    margin-top: 1.5rem;
  }
  .checking_info {
    display: flex;
    background: ${(props) => props.theme.infoBackground};
    padding: 0.75rem;
    border-radius: 6px;
    margin-top: 1.5rem;
    p {
      font-size: 14px;
      margin: 0 0 0 0.5rem;
    }
  }
  .checking_data {
    margin-top: 1.5rem;
    h4 {
      font-size: 12px;
      color: ${(props) => props.theme.lightGrey};
      margin-bottom: 6px;
    }
    .checking_item {
      display: flex;
      align-items: center;
      background: ${(props) => props.theme.bg};
      padding: 14px 1rem;
      border-radius: 6px;
      img {
        height: 32px;
        width: 32px;
        margin-right: 0.5rem;
      }
      h3 {
        flex: 1;
        color: ${(props) => props.theme.default_color};
        font-size: 16px;
        font-weight: 500;
        margin-right: 1rem;
      }
      svg {
        height: 32px;
        width: 32px;
      }
      .checking_value {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .checking_button {
    margin: 1.5rem 0 1rem;
    ${Button} {
      font-size: 16px;
      line-height: normal;
      height: fit-content;
      padding: 0.75rem 2.5rem;
      color: ${(props) => props.theme.superLightBlue};
    }
  }
`;

const StakeModal = ({ handleClose, showModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [keyfiData, setKeyfiData] = useState({
    fetched: false,
    ETHKEYFIValuation: 0,
    BSCKEYFIValuation: 0,
    PolygonKEYFIValuation: 0,
    stakedValuation: 0,
    BSCStakedValuation: 0,
    PolygonStakedValuation: 0,
  });

  const {
    fetched,
    ETHKEYFIValuation,
    BSCKEYFIValuation,
    PolygonKEYFIValuation,
    stakedValuation,
    BSCStakedValuation,
    PolygonStakedValuation,
  } = keyfiData;

  const app = useSelector((state) => state.app);
  const { isLoading } = app;

  const user = useSelector((state) => state.user);
  const { id: userAddress, isWhitelisted } = user;

  const wallet = useSelector((state) => state.wallet);
  const { providerId, staking } = wallet;

  const userData = useSelector((state) => state.userData);
  const { error } = userData;

  const mainnetTokens = dispatch(walletOperations.getWalletNetworkTokens("mainnet"));
  const polygonTokens = dispatch(walletOperations.getWalletNetworkTokens("polyogn"));
  const bscTokens = dispatch(walletOperations.getWalletNetworkTokens("bsc-mainnet"));

  const keyfi = staking.find(
    (item) => item.network === "mainnet" && item.token === "KEYFI" && item.platform === "keyfi"
  );
  const BSCkeyfi = staking.find(
    (item) => item.network === "bsc-mainnet" && item.token === "KEYFI" && item.platform === "keyfi"
  );
  const PolygonKeyfi = staking.find(
    (item) => item.network === "bsc-mainnet" && item.token === "KEYFI" && item.platform === "keyfi"
  );

  const handleApproveStakeAccess = () => {
    if (stakedValuation > 1000 || BSCStakedValuation > 1000 || PolygonStakedValuation > 1000 || isWhitelisted) {
      dispatch(userDataOperations.approveStakeKeyfi("staking"));
    }
  };

  const handleModalTitle = () => {
    if (fetched) {
      // When user has enough staked KEYFI or has credentials
      if (stakedValuation > 1000 || BSCStakedValuation > 1000 || PolygonStakedValuation > 1000 || isWhitelisted)
        return "Congratulations!";

      // When user hasn't staked enough KEYFI, but has enough in wallet
      if (
        (stakedValuation < 1000 || BSCStakedValuation < 1000 || PolygonStakedValuation < 1000) &&
        (ETHKEYFIValuation > 1000 || BSCKEYFIValuation > 1000 || PolygonKEYFIValuation > 1000)
      )
        return "Stake your KEYFI";

      // When user has neither staked enough KEYFI nor has enough KEYFI in wallet
      return "Insufficient KEYFI staked";
    }
    if (userAddress && !error) return "Verifying staked KEYFI balance";
    return "Connect to KeyFi";
  };

  useEffect(() => {
    const checkCredentialsAndStake = async () => {
      try {
        const stakedValuation = keyfi?.amount ?? 0;
        const BSCStakedValuation = BSCkeyfi?.amount ?? 0;
        const PolygonStakedValuation = PolygonKeyfi?.amount ?? 0;

        const ETHKEYFIValuation = mainnetTokens.KEYFI ?? 0;
        const BSCKEYFIValuation = bscTokens.KEYFI ?? 0;
        const PolygonKEYFIValuation = polygonTokens.KEYFI ?? 0;

        setKeyfiData({
          fetched: true,
          ETHKEYFIValuation,
          BSCKEYFIValuation,
          PolygonStakedValuation,
          stakedValuation,
          BSCStakedValuation,
          PolygonKEYFIValuation,
        });
      } catch (err) {
        console.log(err);
      }
    };

    if (!isLoading && showModal) {
      checkCredentialsAndStake();
    }
  }, [isLoading && showModal]);

  const handleWalletConnect = async (providerId) => {
    try {
      dispatch(appActions.setLoading(true));
      dispatch(walletOperations.connect(providerId, null, true));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal title={handleModalTitle()} width="100%" maxwidth="740px" handleCloseModal={handleClose}>
      {/* User has access to the app */}
      {fetched && (stakedValuation > 1000 || isWhitelisted || BSCStakedValuation > 1000) && (
        <CheckingWrapper>
          <p>{t("access.haveAccess")}</p>
          <div className="checking_data">
            <div className="checking_item">
              {tokensLogos["KEYFI"]}
              <h3>{t("access.minimal1000Stake")}</h3>
              <Verified />
            </div>
          </div>
          <div className="checking_data">
            <div className="checking_item">
              {walletsLogos[providerId]}
              <h3>{providerId}</h3>
              <Verified />
            </div>
          </div>

          <ErrorMessage>{error}</ErrorMessage>
          <div className="checking_button">
            <Button primary onClick={handleApproveStakeAccess}>
              {t("access.enter")} KeyFi PRO
            </Button>
          </div>
        </CheckingWrapper>
      )}

      {/* User has enough in the wallet, but not enough staked */}
      {fetched &&
        stakedValuation < 1000 &&
        BSCStakedValuation < 1000 &&
        (ETHKEYFIValuation > 1000 || BSCKEYFIValuation > 1000) && (
          <CheckingWrapper>
            <p>
              {t("access.minimum1000StakeText")} {t("access.minimum1000StakeText2")}
            </p>

            <div className="checking_data">
              <h4>
                {t("common.staked")} KEYFI {t("common.tokens")}
              </h4>
              <div className="checking_item">
                {tokensLogos["KEYFI"]}
                <h3>{beautifyTokensAmount(mainnetTokens.KEYFI, { suffix: " KEYFI" })}</h3>
              </div>
            </div>
            <div className="checking_button">
              <a href="https://app.keyfi.com/earn" target="_blank" rel="noreferrer">
                <Button primary>{t("common.goToEarnPage")}</Button>
              </a>
            </div>
          </CheckingWrapper>
        )}

      {/* User has neither enought in the wallet nor enough staked */}
      {fetched &&
        stakedValuation < 1000 &&
        BSCStakedValuation < 1000 &&
        ETHKEYFIValuation < 1000 &&
        BSCKEYFIValuation < 1000 && (
          <CheckingWrapper>
            <div className="checking_info">
              <Info />
              <p>
                {t("access.minimum1000StakeText")} <br /> {t("access.minimum1000StakeText2")}
              </p>
            </div>

            <div className="checking_data">
              <div className="checking_item">
                {tokensLogos["KEYFI"]}
                <h3>
                  {beautifyTokensAmount(keyfi.KEYFI ? keyfi.KEYFI.amount : BSCkeyfi.KEYFI ? BSCkeyfi.KEYFI.amount : 0, {
                    suffix: " KEYFI",
                  })}
                </h3>
              </div>
            </div>

            <div className="checking_button">
              <a href="https://app.keyfi.com/swap" target="_blank" rel="noreferrer">
                <Button primary>{t("access.goToSwapPage")}</Button>
              </a>
            </div>
          </CheckingWrapper>
        )}

      {!fetched &&
        (userAddress && !error ? (
          <LoadingModalContainer>
            <div>{t("access.walletIsConnect")}</div>
            <div className="loading_modal_selected">
              {walletsLogos[providerId]}
              <h3>{providerId}</h3>
              <Verified />
            </div>
            <SpinningLoader>
              <div className="profile-main-loader">
                <div className="loader">
                  <svg className="circular-loader" viewBox="25 25 50 50">
                    <circle
                      className="loader-path"
                      cx="50"
                      cy="50"
                      r="20"
                      fill="none"
                      stroke="#005AD3"
                      strokeWidth="2"
                    />
                  </svg>
                </div>
              </div>
            </SpinningLoader>
          </LoadingModalContainer>
        ) : (
          <ConnectWalletModal>
            <h2>Wallets</h2>
            <div className="connect_modal_list">
              <div className="connect_modal_item" onClick={() => handleWalletConnect(WalletProviderId.Metamask)}>
                <img src={MetaMask} alt="Connect with MetaMask" />
                MetaMask
              </div>
              <div className="connect_modal_item" onClick={() => handleWalletConnect(WalletProviderId.WalletConnect)}>
                <img src={walletConnect} alt="Connect with WalletConnect" />
                WalletConnect
              </div>
              <div className="connect_modal_item" onClick={() => handleWalletConnect(WalletProviderId.SelfKey)}>
                <img src={SelfKey} alt="Connect with SelfKey" />
                SelfKey
              </div>
            </div>
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </ConnectWalletModal>
        ))}
    </Modal>
  );
};

export default StakeModal;
