import styled from "styled-components";

import { breakPoints } from "../../Theme";

export const WalletValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* Total value container */
  .total_value_outside {
    background: ${(props) => props.theme.secondaryBg};
    border-radius: 16px;
    padding: 0.75rem;
    .total_value_container {
      position: relative;
      background: ${(props) => props.theme.bg};
      border-radius: 12px;
      width: 100%;
      padding: 0.75rem;
      /* Total value title CSS */
      h4 {
        display: flex;
        align-items: center;
        gap: 10px;
        color: ${(props) => props.theme.lightGrey};
        margin-bottom: 6px;
        button {
          height: 14px;
          width: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
      /* Total value CSS */
      h2 {
        font-size: 34px;
        margin-bottom: 0.75rem;
      }
      .total_value_toggle {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 0.25rem;
        font-size: 12px;
        div {
          color: ${(props) => props.theme.lightGrey};
          transition: 0.3s;
        }
        .mode_selected {
          color: ${(props) => props.theme.mainDarkText};
        }
        input[type="checkbox"] {
          display: none;
          :checked + label:after {
            left: calc(100% - 2px);
            transform: translateX(-100%);
          }
        }
        label {
          display: block;
          width: 37px;
          height: 20px;
          background: ${(props) => props.theme.toggleGreenOpacity};
          border-radius: 26px;
          border: 1px solid ${(props) => props.theme.toggleGreen};
          position: relative;
          cursor: pointer;
          transition: 0.3s;
          ::after {
            content: "";
            height: 12px;
            width: 12px;
            background: ${(props) => props.theme.toggleBall};
            position: absolute;
            border: 1px solid ${(props) => props.theme.toggleBallBorder};
            border-radius: 50%;
            top: 2px;
            left: 2px;
            transition: 0.3s;
          }
        }
      }
      /* Chart buttons CSS */
      .total_value_charts {
        position: absolute;
        top: 1rem;
        right: 1rem;
        display: flex;
        gap: 4px;
        background: ${(props) => props.theme.secondaryBg};
        padding: 0.25rem;
        border-radius: 10px;
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 6px;
          border-radius: 8px;
          path {
            fill: ${(props) => props.theme.lightGrey};
          }
          rect {
            stroke: ${(props) => props.theme.lightGrey};
          }
        }
        .chart_selected {
          background: ${(props) => props.theme.bg};
          path {
            fill: white;
          }
          rect {
            stroke: white;
          }
        }
      }
    }
  }
  /* END OF Total value container */
  /* Wallet value chart container */
  .wallet_value_chart_container {
    max-height: 280px;
    min-height: 280px;
    overflow-y: auto;
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;

    /* Scrollbar */
    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.researchBorder};
      border-radius: 6px;
    }
    @media (min-width: ${breakPoints.xxl}px) {
      max-height: 100%;
      width: 100%;
    }
  }
`;

export const WalletValueBarChart = styled.div`
  padding-right: 0.5rem;
  height: 100%;
  .wallet_value_bar {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    :not(:last-of-type) {
      margin-bottom: 1rem;
    }
    .wallet_value_bar_info {
      display: flex;
      align-items: center;
      gap: 6px;
      .wallet_value_bar_icon {
        display: block;
        min-height: 20px;
        min-width: 20px;
        max-height: 20px;
        max-width: 20px;
        border-radius: 50%;
      }
      h4 {
        font-size: 12px;
        color: ${(props) => props.theme.lightGrey};
        width: 80px;
      }
    }
    .wallet_value_rect_wrapper {
      height: 20px;
      .wallet_value_rect {
        height: 100%;
        width: 0;
        animation: barWidth 1s ease-in forwards;
      }
    }

    .wallet_value_chart_total {
      opacity: 0;
      animation: opacity 0.25s 1s ease-in forwards;
    }
  }
  @keyframes barWidth {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  @keyframes opacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const LegendWrapper = styled.div`
  max-height: 280px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  .legend_wrapper_scroll {
    margin-left: 0.5rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    gap: 1rem;
    flex: 1;
    /* Scrollbar */
    ::-webkit-scrollbar {
      display: none;
    }

    ::-webkit-scrollbar-track {
      background: none;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.researchBorder};
      border-radius: 10px;
    }
    /* END OF Scrollbar */

    .legend_item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 0.5rem;
      width: 100%;
      .legend_item_text {
        h5 {
          font-size: 14px;
          margin-bottom: 2px;
        }
        h4 {
          font-size: 12px;
          color: ${(props) => props.theme.lightGrey};
        }
      }
      @media (max-width: ${breakPoints.md}px) {
        .legend_item {
          margin-right: 10px;
        }
      }
    }
  }
  .wallet_data_shadow {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;
    background: linear-gradient(180deg, rgba(16, 25, 38, 0) 0%, #101926 128.41%);
  }
`;

export const CustomTooltipWrapper = styled.div`
  padding: 0.5rem 1rem;
  background: ${(props) => props.theme.brandBlack};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.borderBg};
  box-shadow: 0px 5.8353px 25.7801px rgba(0, 0, 0, 0.15);
  .tooltip_value {
    color: ${(props) => props.theme.greenShade2};
  }
`;
