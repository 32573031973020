import { poolsLogos } from "../../helpers";
import Token from "../../helpers/token";

const platforms = [
  {
    id: "uniswap",
    network: 1,
    networkName: "mainnet",
    text: "Uniswap",
    type: "univ2",
    img: poolsLogos.Uniswap,
    defaultSelected: [
      new Token(1, "0x0000000000000000000000000000000000000000", "ETH", 18),
      new Token(1, "0xb8647e90c0645152fccf4d9abb6b59eb4aa99052", "KEYFI", 18),
    ],
  },
  {
    id: "apeswap",
    network: 56,
    networkName: "bsc",
    text: "ApeSwap",
    type: "univ2",
    img: poolsLogos.ApeSwap,
    defaultSelected: [
      new Token(56, "0x0000000000000000000000000000000000000000", "BNB", 18),
      new Token(56, "0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d", "KEYFI", 18),
    ],
  },
  {
    id: "pancakeswap",
    network: 56,
    networkName: "bsc",
    text: "PancakeSwap",
    versions: ["v1", "v2"],
    type: "univ2",
    img: poolsLogos.PancakeSwap,
    defaultSelected: [
      new Token(56, "0xe9e7cea3dedca5984780bafc599bd69add087d56", "BUSD", 18),
      new Token(56, "0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d", "KEYFI", 18),
    ],
  },

  {
    id: "quickswap",
    network: 137,
    networkName: "polygon",
    text: "QuickSwap",
    type: "univ2",
    img: poolsLogos.QuickSwap,
    defaultSelected: [
      new Token(137, "0x831753dd7087cac61ab5644b308642cc1c33dc13", "QUICK", 18),
      new Token(137, "0xd1a5f2a049343fc4d5f8d478f734eba51b22375e", "KEYFI", 18),
    ],
  },
  {
    id: "trisolaris",
    network: 1313161554,
    networkName: "aurora",
    text: "Trisolaris",
    type: "univ2",
    img: poolsLogos.Trisolaris,
    defaultSelected: [
      new Token(1313161554, "0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB", "WETH", 18),
      new Token(1313161554, "0xb12bfca5a55806aaf64e99521918a4bf0fc40802", "USDC", 6),
    ],
  },
  // {
  //   id: "curve",
  //   network: 1,
  //   networkName: "mainnet",
  //   text: "Curve",
  //   img: poolsLogos.Curve,
  // },
];

// 1INCH DATA

// const platforms = [
//   {
//     id: "inche",
//     text: "1inch",
//     network: "mainnet",
//     img: poolsLogos["1Inch"],
//     defaultSelected: ["ETH", "USDC"],
//     estimateSwap: async (selectinput1, value, selectinput2, reverse = false) => {
//       let estimation;
//       if (!reverse) {
//         estimation = await integrations.inch.estimateSwap(selectinput1, value, selectinput2);
//       } else {
//         estimation = await integrations.inch.estimateSwap(selectinput2, value, selectinput1);
//       }
//       return estimation;
//     },
//     getSupportedAssets: async () => {
//       const tokens = ERC20Tokens;
//       return tokens.map((item) => {
//         return {
//           id: item.symbol,
//           text: item.symbol,
//           address: item.id,
//           img: (
//             <AssetImage
//               className="swap_token_image"
//               src={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${getAddressSum(
//                 item.id
//               )}/logo.png`}
//               alt={item.symbol}
//             />
//           ),
//         };
//       });
//     },
//     swap: async (selectinput1, input1, selectinput2, estimation, options) => {
//       try {
//         const response = await integrations.inch.swap(selectinput1, input1, selectinput2, options);
//         return response;
//       } catch (err) {
//         throw err;
//       }
//     },
//   },

//
// ];

export default platforms;
