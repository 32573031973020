import React, { useEffect, useState } from "react";

import { getAddressSum } from "@keyfi/keyfi-common/src/integrations";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as ArrowDown } from "../../assets/arrowDown.svg";
import { ReactComponent as MagnifyingGlass } from "../../assets/discovery/magnifyingGlass.svg";
import { assetImageChainID, beautifyTokensAmount, tokensLogos, wrapperNetworkToken } from "../../helpers";
import AssetImage from "../Borrow/AssetImage";
import Modal from "../Modal";
import { OptionsWrapper, StyledOption, StyledOptions } from "./SwapDropdown.styles";

const Wrapper = styled.div`
  padding: 10px 0.75rem;
  border: 1px solid ${(props) => props.theme.researchBorder};
  border-radius: 6px;
  background: ${(props) => props.theme.bg};
  display: flex;
  gap: 0.5rem;
  .token_selected_token {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    img {
      height: 20px;
      width: 20px;
      border-radius: 50%;
    }
    .arrow_down {
      width: 12px;
      width: 12px;
    }
  }
  input {
    width: 100%;
    font-size: 20px;
    text-align: right;
    color: ${(props) => props.theme.form_text};
  }
`;

const TokenInput = ({ selectedOption, network, networkTokens, supportedAssets, setOption, name, onChange, value }) => {
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState(supportedAssets);
  const { t } = useTranslation();

  const wallet = useSelector((state) => state.wallet);
  const { selectedAddress } = wallet;

  const handleSelect = (data) => {
    setOpen(false);
    setSearchQuery("");
    setOption(data);
  };

  useEffect(() => {
    if (searchQuery.length !== 0) {
      setData(
        supportedAssets
          .filter((item) => item.symbol.toLowerCase().includes(searchQuery.toLowerCase()))
          .sort((a, b) => {
            const aValue = networkTokens[a.symbol.toUpperCase()] ?? 0;
            const bValue = networkTokens[b.symbol.toUpperCase()] ?? 0;
            return bValue - aValue;
          })
          .slice(0, 20)
      );
    } else {
      setData(
        supportedAssets
          .sort((a, b) => {
            const aValue = networkTokens[a.symbol.toUpperCase()] ?? 0;
            const bValue = networkTokens[b.symbol.toUpperCase()] ?? 0;
            return bValue - aValue;
          })
          .slice(0, 20)
      );
    }
  }, [searchQuery, supportedAssets, network, selectedAddress]);

  return (
    <Wrapper>
      {open && (
        <Modal
          title={t("swap.chooseAtoken")}
          handleCloseModal={() => {
            setSearchQuery("");
            setOpen(false);
          }}
        >
          <OptionsWrapper>
            <div className="input-wrapper">
              <input
                className="search"
                type="text"
                placeholder={t("common.searchTokenName")}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <MagnifyingGlass />
            </div>
            {data.length ? (
              <StyledOptions>
                {data.map((option) => (
                  <StyledOption key={option.address + Math.random()} onClick={() => handleSelect(option)}>
                    <div className="token-info">
                      <AssetImage
                        fallbackLogo={tokensLogos[option.symbol]}
                        className="swap_token_image"
                        src={
                          option.logoURI ||
                          `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${
                            assetImageChainID[network]
                          }/assets/${
                            option.address === "0x0000000000000000000000000000000000000000"
                              ? getAddressSum(wrapperNetworkToken[network])
                              : getAddressSum(option.address)
                          }/logo.png`
                        }
                        alt={option.symbol}
                      />
                      <span>{option.symbol}</span>
                    </div>
                    <span className="token-value">{beautifyTokensAmount(networkTokens[option.symbol])}</span>
                  </StyledOption>
                ))}
              </StyledOptions>
            ) : (
              t("common.noResultFound")
            )}
          </OptionsWrapper>
        </Modal>
      )}
      <div className="token_selected_token" onClick={() => setOpen(true)}>
        <AssetImage
          cons
          fallbackLogo={tokensLogos[selectedOption.symbol]}
          className="swap_token_image"
          src={
            selectedOption.logoURI ||
            `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${
              assetImageChainID[network]
            }/assets/${
              selectedOption.address === "0x0000000000000000000000000000000000000000"
                ? getAddressSum(wrapperNetworkToken[network])
                : getAddressSum(selectedOption.address)
            }/logo.png`
          }
          alt={selectedOption.symbol}
        />
        <p>{selectedOption.symbol}</p>
        <ArrowDown className="arrow_down" />
      </div>
      <input name={name} onChange={onChange} value={value} />
    </Wrapper>
  );
};

export default TokenInput;
