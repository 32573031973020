import React, { useRef, useState } from "react";

import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";

// Assets
import { ReactComponent as ArrowDown } from "../../assets/arrowDown.svg";
import SmallLogo from "../../assets/Blue-only-logo.svg";
import { ReactComponent as BlockchainIcon } from "../../assets/header/blockchain.svg";
import { ReactComponent as GasStationIcon } from "../../assets/header/gasStation.svg";
import { ReactComponent as GearIcon } from "../../assets/header/gear.svg";
import { ReactComponent as UserIcon } from "../../assets/header/user.svg";
import { ReactComponent as WorldIcon } from "../../assets/header/world.svg";
import { ReactComponent as ActionsIcon } from "../../assets/sidebar/actions.svg";
import { ReactComponent as AlertIcon } from "../../assets/sidebar/Alert.svg";
import { ReactComponent as BorrowIcon } from "../../assets/sidebar/borrow.svg";
import OpenCloseSidebarIcon from "../../assets/sidebar/close.svg";
import CloseIcon from "../../assets/sidebar/closeIcon.svg";
import { ReactComponent as DashboardIcon } from "../../assets/sidebar/dashboard.svg";
import { ReactComponent as DepositIcon } from "../../assets/sidebar/deposit.svg";
import { ReactComponent as DiscoveryIcon } from "../../assets/sidebar/Discovery.svg";
import { ReactComponent as EarnIcon } from "../../assets/sidebar/earn.svg";
import { ReactComponent as HackenIcon } from "../../assets/sidebar/hacken.svg";
import Logo from "../../assets/sidebar/KeyFi_full_white.svg";
import { ReactComponent as MarketAnalysisIcon } from "../../assets/sidebar/market_analysis.svg";
import { ReactComponent as NewsIcon } from "../../assets/sidebar/news-menu-icon.svg";
import { ReactComponent as PoolsIcon } from "../../assets/sidebar/pools.svg";
import Pro from "../../assets/sidebar/PRO.svg";
// import { ReactComponent as RebalancerIcon } from "../../assets/sidebar/rebalancer.svg";
import { ReactComponent as RecommendationIcon } from "../../assets/sidebar/recommendation.svg";
import { ReactComponent as StrategyManagerIcon } from "../../assets/sidebar/strategyManager.svg";
import { ReactComponent as SwapIcon } from "../../assets/sidebar/swap.svg";
import { ReactComponent as TransactionsIcon } from "../../assets/sidebar/transactions.svg";
import { ReactComponent as WatchlistIcon } from "../../assets/sidebar/Watchlist.svg";
import { ReactComponent as YieldIcon } from "../../assets/sidebar/yield.svg";
import { trimAddress } from "../../helpers";
import { useWallet } from "../../helpers/useWallet";
import { userDataOperations } from "../../redux/userDataSlice";
import Modal from "../Modal";
import MobileGas from "./MobileGas";
import {
  Audited,
  BlockchainMobileInfo,
  ConnectWalletButton,
  SidebarBackground,
  SidebarHeader,
  SidebarNavAccordionWrapper,
  SidebarNavItem,
  SidebarNavigation,
  SidebarV2Wrapper,
} from "./SidebarV2.styles";

const SidebarMobileSettings = ({ sidebarMinimized, title, icon, options, isMobile, handleToggleSidebar }) => {
  const [open, setOpen] = useState(false);
  return (
    <SidebarNavAccordionWrapper sidebarMinimized={sidebarMinimized}>
      <div className="sidebar_acc_main" onClick={() => setOpen(!open)}>
        {icon && icon}
        {!sidebarMinimized && title}
        <ArrowDown className="sidebar_acc_main_arrow" style={{ transform: open ? "rotate(180deg)" : "" }} />
      </div>
      <div className="sidebar_acc_list" style={{ display: open ? "block" : "none" }}>
        {options.map((item) => {
          if (item.to) {
            return (
              <NavLink
                key={item.id}
                activeClassName="active"
                to={item.to}
                onClick={isMobile ? handleToggleSidebar : null}
              >
                <SidebarNavItem className="sidebar_acc_item">
                  {item.icon && item.icon}
                  {item.text}
                </SidebarNavItem>
              </NavLink>
            );
          }
          if (item.onClick) {
            return (
              <SidebarNavItem key={item.id} onClick={item.onClick} className="sidebar_acc_item">
                {item.icon && item.icon}
                {item.text}
              </SidebarNavItem>
            );
          }
          return (
            <a key={item.id} activeClassName="active" href={item.href} onClick={isMobile ? handleToggleSidebar : null}>
              <SidebarNavItem className="sidebar_acc_item">
                {item.icon && item.icon}
                {item.text}
              </SidebarNavItem>
            </a>
          );
        })}
      </div>
    </SidebarNavAccordionWrapper>
  );
};

const SidebarNavAccordion = ({ sidebarMinimized, isMobile, handleToggleSidebar }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <SidebarNavAccordionWrapper sidebarMinimized={sidebarMinimized}>
      <div className="sidebar_acc_main" onClick={() => setOpen(!open)}>
        <YieldIcon className="sidebar_acc_main_icon" />
        {!sidebarMinimized && t("sidebar.yield")}
        <ArrowDown className="sidebar_acc_main_arrow" style={{ transform: open ? "rotate(180deg)" : "" }} />
      </div>
      <div className="sidebar_acc_list" style={{ display: open ? "block" : "none" }}>
        {/* <NavLink activeClassName="active" to="/rebalancer" onClick={isMobile ? handleToggleSidebar : null}>
          <SidebarNavItem className="sidebar_acc_item">
            <RebalancerIcon />
            {!sidebarMinimized && t("sidebar.rebalancer")}
          </SidebarNavItem>
        </NavLink> */}
        <NavLink activeClassName="active" to="/deposit" onClick={isMobile ? handleToggleSidebar : null}>
          <SidebarNavItem className="sidebar_acc_item">
            <DepositIcon />
            {!sidebarMinimized && t("common.deposit")}
          </SidebarNavItem>
        </NavLink>
        <NavLink activeClassName="active" to="/pools" onClick={isMobile ? handleToggleSidebar : null}>
          <SidebarNavItem className="sidebar_acc_item">
            <PoolsIcon />
            {!sidebarMinimized && t("sidebar.pools")}
          </SidebarNavItem>
        </NavLink>
      </div>
    </SidebarNavAccordionWrapper>
  );
};

const SidebarV2 = ({
  sidebarMinimized,
  handleShowModal,
  languageData,
  handleLanguage,
  isMobile,
  handleToggleSidebar,
  handleMinimize,
  showSidebar,
}) => {
  const dispatch = useDispatch();
  const [showGas, setShowGas] = useState(false);
  const wallet = useWallet();
  const { t, i18n } = useTranslation();

  const ref = useRef(null);

  const onScroll = () => {
    if (ref.current) {
      const { scrollTop, scrollHeight, clientHeight } = ref.current;
      if (scrollTop + clientHeight === scrollHeight) {
        return (ref.current.className = "sidebar_nav no_shadow_bottom");
      }
      if (scrollTop === 0 || !scrollTop) {
        return (ref.current.className = "sidebar_nav no_shadow_top");
      }
      return (ref.current.className = "sidebar_nav");
    }
  };

  const settingsOptions = [
    {
      id: "lang",
      text: languageData[i18n.languages[0]].title,
      icon: <WorldIcon />,
      onClick: handleLanguage,
    },
  ];

  const accountOptions = [
    { id: "account", text: t("common.myAccount"), to: "/user" },
    { id: "info", text: t("sidebar.KEYFI Info"), to: "/info" },
    { id: "governance", text: t("common.governance"), href: "https://vote.keyfi.com/#/" },
    { id: "logout", text: "Logout", onClick: () => dispatch(userDataOperations.logout()) },
  ];

  const gas = useSelector((state) => state.gas.gasData);

  return (
    <>
      <SidebarV2Wrapper showSidebar={showSidebar} sidebarMinimized={sidebarMinimized} isMobile={isMobile}>
        {!isMobile && sidebarMinimized && (
          <div className="close-icon">
            <img src={OpenCloseSidebarIcon} alt="open close icon" onClick={handleMinimize} />
          </div>
        )}
        {/* Desktop sidebar open */}
        {!sidebarMinimized && !isMobile && (
          <SidebarHeader>
            <>
              <Link
                to="/dashboard"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <img src={Logo} alt="logo" />
                <img src={Pro} alt="PRO" className="pro_logo" />
              </Link>
              <img src={OpenCloseSidebarIcon} alt="open close icon" className="close-icon" onClick={handleMinimize} />
            </>
          </SidebarHeader>
        )}
        {/* Desktop sidebar closed */}
        {sidebarMinimized && !isMobile && (
          <SidebarHeader>
            <Link
              to="/dashboard"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={SmallLogo} alt="logo" />
              <img style={{ marginTop: "3px" }} src={Pro} alt="PRO" className="pro_logo" />
            </Link>
          </SidebarHeader>
        )}
        {/* On mobile */}
        {isMobile && (
          <SidebarHeader>
            <Link to="/dashboard" style={{ display: "flex", alignItems: "center" }}>
              <img src={Logo} alt="logo" />
              <img src={Pro} alt="Pro" width="48px" height="18px" />
            </Link>
            <img src={CloseIcon} alt="open close icon" className="close-icon" onClick={handleToggleSidebar} />
          </SidebarHeader>
        )}
        {isMobile &&
          (wallet.address ? (
            <BlockchainMobileInfo>
              <div onClick={handleShowModal}>
                <BlockchainIcon height="26px" />
                <span>{trimAddress(wallet.address)}</span>
              </div>

              <div onClick={() => setShowGas(true)}>
                <GasStationIcon height="28px" />
                {!!gas.average && <span> {BigNumber(gas.average).shiftedBy(-9).toPrecision(2)}</span>}
              </div>
              {showGas && (
                <Modal title={t("common.gasPrice")} handleCloseModal={() => setShowGas(false)}>
                  <MobileGas />
                </Modal>
              )}
            </BlockchainMobileInfo>
          ) : (
            <ConnectWalletButton primary onClick={handleShowModal}>
              {t("dashboard.connectWallet")}
            </ConnectWalletButton>
          ))}
        <SidebarNavigation>
          <div className="sidebar_nav no_shadow_top" ref={ref} onScroll={onScroll}>
            <div className="sidebar_nav_shadow shadow_top" />
            <NavLink activeClassName="active" to="/dashboard" onClick={isMobile ? handleToggleSidebar : null}>
              <SidebarNavItem>
                <DashboardIcon />
                {!sidebarMinimized && t("sidebar.dashboard")}
              </SidebarNavItem>
            </NavLink>
            <NavLink activeClassName="active" to="/research" onClick={isMobile ? handleToggleSidebar : null}>
              <SidebarNavItem>
                <DiscoveryIcon />
                {!sidebarMinimized && t("sidebar.research")}
              </SidebarNavItem>
            </NavLink>
            <NavLink activeClassName="active" to="/watchlist" onClick={isMobile ? handleToggleSidebar : null}>
              <SidebarNavItem>
                <WatchlistIcon />
                {!sidebarMinimized && t("sidebar.watchlist")}
              </SidebarNavItem>
            </NavLink>
            <NavLink activeClassName="active" to="/strategy" onClick={isMobile ? handleToggleSidebar : null}>
              <SidebarNavItem>
                <StrategyManagerIcon />
                {!sidebarMinimized && t("sidebar.strategy")}
              </SidebarNavItem>
            </NavLink>
            <NavLink activeClassName="active" to="/discovery_engine" onClick={isMobile ? handleToggleSidebar : null}>
              <SidebarNavItem>
                <RecommendationIcon />
                {!sidebarMinimized && "AI Analysis"}
              </SidebarNavItem>
            </NavLink>
            <NavLink activeClassName="active" to="/news" onClick={isMobile ? handleToggleSidebar : null}>
              <SidebarNavItem>
                <NewsIcon />
                {!sidebarMinimized && "News"}
              </SidebarNavItem>
            </NavLink>
            <NavLink activeClassName="active" to="/alerts" onClick={isMobile ? handleToggleSidebar : null}>
              <SidebarNavItem>
                <AlertIcon />
                {!sidebarMinimized && t("sidebar.alerts")}
              </SidebarNavItem>
            </NavLink>
            <NavLink activeClassName="active" to="/actions" onClick={isMobile ? handleToggleSidebar : null}>
              <SidebarNavItem>
                <ActionsIcon />
                {!sidebarMinimized && t("sidebar.actions")}
              </SidebarNavItem>
            </NavLink>
            <NavLink activeClassName="active" to="/swap" onClick={isMobile ? handleToggleSidebar : null}>
              <SidebarNavItem>
                <SwapIcon />
                {!sidebarMinimized && t("sidebar.swap")}
              </SidebarNavItem>
            </NavLink>
            <NavLink activeClassName="active" to="/market_analysis" onClick={isMobile ? handleToggleSidebar : null}>
              <SidebarNavItem>
                <MarketAnalysisIcon />
                {!sidebarMinimized && "Market Analysis"}
              </SidebarNavItem>
            </NavLink>

            <SidebarNavAccordion
              sidebarMinimized={sidebarMinimized}
              isMobile={isMobile}
              handleToggleSidebar={handleToggleSidebar}
            />
            <NavLink activeClassName="active" to="/borrow" onClick={isMobile ? handleToggleSidebar : null}>
              <SidebarNavItem>
                <BorrowIcon />
                {!sidebarMinimized && t("sidebar.borrow")}
              </SidebarNavItem>
            </NavLink>
            <NavLink activeClassName="active" to="/transactions" onClick={isMobile ? handleToggleSidebar : null}>
              <SidebarNavItem>
                <TransactionsIcon className="sidebar_icon_nofill" />
                {!sidebarMinimized && t("sidebar.transactions")}
              </SidebarNavItem>
            </NavLink>
            <NavLink activeClassName="active" to="/earn" onClick={isMobile ? handleToggleSidebar : null}>
              <SidebarNavItem className="sidebar_acc_item">
                <EarnIcon />
                {!sidebarMinimized && t("sidebar.KEYFI Earn")}
              </SidebarNavItem>
            </NavLink>
            {isMobile && (
              <SidebarMobileSettings
                isMobile={isMobile}
                handleToggleSidebar={handleToggleSidebar}
                icon={<GearIcon className="sidebar_acc_main_icon" />}
                title="Settings"
                sidebarMinimized={sidebarMinimized}
                options={settingsOptions}
              />
            )}
            <div className="sidebar_nav_shadow shadow_bottom" />
          </div>
        </SidebarNavigation>
        {isMobile && (
          <>
            <SidebarMobileSettings
              isMobile={isMobile}
              handleToggleSidebar={handleToggleSidebar}
              icon={<UserIcon className="sidebar_acc_main_icon" />}
              title="Account"
              sidebarMinimized={sidebarMinimized}
              options={accountOptions}
            />
          </>
        )}
        {!isMobile && (
          <Audited>
            {!sidebarMinimized && "Audited by:"}
            <a
              href="https://keyfi.com/static/Keyfi_SC_Audit_Report-78d8894ee980f2a7036a7a0f7a593a4b.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <HackenIcon />
            </a>
          </Audited>
        )}
      </SidebarV2Wrapper>
      {isMobile && <SidebarBackground showBackground={showSidebar} onClick={handleToggleSidebar} />}
    </>
  );
};

export default SidebarV2;
